// CSS Style
import 'styles/public'

import Rails from "@rails/ujs"
import Cookies from 'js-cookie';
import 'public/base';

Rails.start();

window.jQuery = $;
window.$ = $;
window.Cookies = Cookies;
