$(document).ready(function () {
  var totalQuestions = $('.question').length - 1;

  $('.answer-button').on('click', function () {
    var questionNumber = $(this).data('question');
    var selectedAnswer = $(this).data('answer');
    var feedbackElement = $('#feedback-' + questionNumber);
    var correctAnswer = feedbackElement.data('answer');
    $('.queation-title').hide();

    feedbackElement.find('div[data-feedback]').hide();

    if (selectedAnswer === correctAnswer) {
      feedbackElement.find('div[data-feedback="correct"]').show();
      feedbackElement.find('div[data-feedback="incorrect"]').hide();
      $(this).addClass('correct-answer');
      $(this).siblings().hide();
    } else {
      feedbackElement.find('div[data-feedback="correct"]').hide();
      feedbackElement.find('div[data-feedback="incorrect"]').show();
      $(this).addClass('wrong-answer');
      $(this).siblings().hide();
    }

    feedbackElement.show();

    $('#start-over-' + questionNumber).show().siblings('.next-question-button').show();
    $(this).siblings().addBack().prop('disabled', true);

    if (questionNumber < totalQuestions) {
      $('#question-' + questionNumber + ' .next-question-button').show();
    } else {
      $('#finish-quiz').show();
    }
  });

  $('.next-question-button').on('click', function () {
    var currentQuestionNumber = $(this).data('question');
    $('#question-' + currentQuestionNumber).removeClass('active');
    $('#question-' + (currentQuestionNumber + 1)).addClass('active');
    $('.queation-title, .answer-button').show();
  });

  $('.start-over-button').on('click', function () {
    var questionNumber = $(this).parent().siblings('.options').find('.answer-button').data('question');
    // 重置該題目、回到初始狀態
    $('#question-' + questionNumber + ' .answer-button').prop('disabled', false).removeClass('correct-answer wrong-answer');
    $('#feedback-' + questionNumber).hide().find('div[data-feedback]').hide();
    $('.queation-title, .answer-button').show();
    $('#start-over-' + questionNumber).hide().siblings('.next-question-button').hide();
  });

  $('#finish-quiz').on('click', function () {
    $('.question.active').removeClass('active');
    $('#result').show();
  });

  // 初始化遊戲
  function startGame(){
    $('.question').removeClass('active');
    $('#question-0').addClass('active');
    $('.answer-button').prop('disabled', false).removeClass('correct-answer wrong-answer');
    $('.feedback').hide()
    $('.next-question-button:not(".final")').css("display", "none")
  }
  startGame();

  // 重新挑戰
  $('#restart').on('click', function() {
    location.reload();
  })
});
